import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  TextInput,
  InputGroup,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Loading from '../Loading'
import NotFound from '../NotFound'

import { updateAccountSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const Account = ({ id }) => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.USER_BUSINESS, {
    variables: { id },
    suspend: false,
  })
  const updateAccount = useMutation(mutations.users.ACCOUNT)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setSuccess(null)
    setError(null)

    const variables = {
      ...updateAccountSchema.cast(values),
      id: get(data, 'user.accounts[0].id', ''),
    }

    try {
      await updateAccount({ variables })
      setSuccess('User was successfully updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>
  if (!loading && !data.user) return <NotFound noMargin />

  return (
    <Card>
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              name: get(data, 'user.accounts[0].name', ''),
              description: get(data, 'user.accounts[0].description', ''),
              geofenceLimit: get(data, 'user.accounts[0].geofenceLimit', ''),
              rewardLimit: get(data, 'user.accounts[0].rewardLimit', ''),
              cta: get(data, 'user.accounts[0].cta', '') || '',
              url: get(data, 'user.accounts[0].url', '') || '',
              imageUrl: get(data, 'user.accounts[0].imageUrl', '') || '',
            }}
            validationSchema={updateAccountSchema}
            onSubmit={onSubmit}
            submitLabel="Update Account"
            error={error}
            success={success}
          >
            <FormSection>
              <TextInput name="name" placeholder="Enter business name" label="Business Name" />
              <TextInput name="imageUrl" placeholder="Enter logo URL" label="Logo URL" />
              <TextInput name="description" placeholder="Enter business description" label="Business Description" area />
            </FormSection>

            <FormSection heading="Call to Action">
              <TextInput name="cta" placeholder="Enter a call to action" label="Call to Action" />
              <TextInput name="url" placeholder="Enter a URL" label="URL" />
            </FormSection>

            <FormSection heading="Limits">
              <InputGroup>
                <TextInput type="number" name="geofenceLimit" placeholder="Enter a zone limit" label="Zone Limit" />
                <TextInput type="number" name="rewardLimit" placeholder="Enter a reward limit" label="Reward Limit" />
              </InputGroup>
            </FormSection>
          </Form>
        )
      }
    </Card>
  )
}

export default Account
