import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import { Form, TextInput } from '../Form'
import Card from '../Card'
import Loading from '../Loading'

import { updateAccountCTA } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'

const CTA = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.BUSINESS, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const updateAccount = useMutation(mutations.users.ACCOUNT, {
    // needed because `queries.users.BUSINESS` and `mutations.users.BUSINESS` hit different collections
    refetchQueries: queries.users.BUSINESS,
  })

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    const variables = {
      ...updateAccountCTA.cast(values),
      id: get(data, 'me.accounts[0].id', ''),
    }

    try {
      await updateAccount({ variables })
      setSuccess('Your account was updated')
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>

  return (
    <Card
      heading="Call to Action"
      text="Give your customers a way to view your website, menu, etc."
    >
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              cta: get(data, 'me.accounts[0].cta', '') || '',
              url: get(data, 'me.accounts[0].url', '') || '',
            }}
            validationSchema={updateAccountCTA}
            onSubmit={onSubmit}
            success={success}
            error={error}
          >
            <TextInput name="cta" placeholder="Enter a call to action" label="Call to Action" />
            <TextInput name="url" placeholder="Enter a URL" label="URL" />
          </Form>
        )
      }
    </Card>
  )
}

export default CTA
