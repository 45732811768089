import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import { TextInput, InputGroup } from '../Form'
import Card from '../Card'
import Loading from '../Loading'

import queries from '../../queries'

const Limits = () => {
  const { data, error, loading } = useQuery(queries.users.LIMITS, {
    suspend: false,
    fetchPolicy: 'network-only',
  })

  if (error && error.message) return <Text color="error">{error.message}</Text>

  return (
    <Card
      heading="Limits"
      text="Your account includes a limited number of zones and rewards."
    >
      {loading
        ? <Loading />
        : (
          <InputGroup>
            <TextInput label="Zone Limit" value={get(data, 'me.accounts[0].geofenceLimit', '')} readOnly disabled />
            <TextInput label="Reward Limit" value={get(data, 'me.accounts[0].rewardLimit', '')} readOnly disabled />
          </InputGroup>
        )
      }
    </Card>
  )
}

export default Limits
