import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateReward(
    $name: String!
    $description: String!
    $points: Int!
    $status: RewardStatus!
    $account: ID
  ) {
    createReward(
      data: {
        name: $name
        description: $description
        points: $points
        status: $status
        account: { connect: { id: $account } }
      }
    ) {
      id
      name
      description
      points
      status
      account {
        id
      }
    }
  }
`

const UPDATE = gql`
  mutation UpdateReward(
    $id: ID!
    $name: String!
    $description: String!
    $points: Int!
    $status: RewardStatus!
  ) {
    updateReward(
      where:{ id: $id }
      data: {
        name: $name
        description: $description
        points: $points
        status: $status
      }
    ) {
      id
      name
      description
      points
      status
    }
  }
`

const DELETE = gql`
  mutation DeleteReward($id: ID!) {
    deleteReward(where: { id: $id }) {
      id
    }
  }
`

const rewards = {
  CREATE,
  UPDATE,
  DELETE,
}

export default rewards
