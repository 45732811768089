import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import {
  Form,
  TextInput,
  FormSection,
} from '../Form'
import Card from '../Card'
import Loading from '../Loading'

import { updateCredentialsSchema } from '../../utilities/validations'
import queries from '../../queries'
import mutations from '../../mutations'
import { auth } from '../../utilities/firebase'

const Profile = () => {
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)
  const { data, error: queryError, loading } = useQuery(queries.users.PROFILE, {
    suspend: false,
    fetchPolicy: 'network-only',
  })
  const updateProfile = useMutation(mutations.users.CREDENTIALS)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)
    setError(null)
    setSuccess(null)

    const variables = {
      ...updateCredentialsSchema.cast(values),
      id: get(data, 'me.id', ''),
      // password must be null (not empty string) or we have yup issues on the server
      password: values.password ? values.password : null,
    }

    try {
      await updateProfile({ variables })
      setSuccess('Your account was updated')
      setTimeout(() => auth.signOut(), 500)
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  if (queryError && queryError.message) return <Text color="error">{queryError.message}</Text>

  return (
    <Card
      heading="Credentials"
      text="If you change this information you will need to login again."
    >
      {loading
        ? <Loading />
        : (
          <Form
            initialValues={{
              email: get(data, 'me.email', ''),
              password: '',
            }}
            validationSchema={updateCredentialsSchema}
            onSubmit={onSubmit}
            success={success}
            error={error}
          >
            <FormSection>
              <TextInput name="email" placeholder="Email address" label="Email address" />
              <TextInput name="password" placeholder="********" label="New Password" type="password" />
            </FormSection>
          </Form>
        )
      }
    </Card>
  )
}

export default Profile
