import { gql } from 'apollo-boost'

const CREATE = gql`
  mutation CreateUser(
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
    $roles: [UserRole!]!
  ) {
    createUser(
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
      roles: $roles
    ) {
      id
      email
      firstName
      lastName
      roles
    }
  }
`

const UPDATE = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String!
    $firstName: String!
    $lastName: String!
    $roles: [UserRole!]!
  ) {
    updateUser(
      id: $id
      email: $email
      firstName: $firstName
      lastName: $lastName
      roles: $roles
    ) {
      id
      email
      firstName
      lastName
      roles
    }
  }
`

const GENERAL = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String
    $lastName: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      firstName
      lastName
    }
  }
`

const CREDENTIALS = gql`
  mutation UpdateUser(
    $id: ID!
    $email: String
    $password: String
  ) {
    updateUser(
      id: $id
      email: $email
      password: $password
    ) {
      id
      email
    }
  }
`

const ACCOUNT = gql`
  mutation UpdateAccount(
    $id: ID!
    $name: String
    $description: String
    $geofenceLimit: Int
    $rewardLimit: Int
    $cta: String
    $url: String
    $imageUrl: String
  ) {
    updateAccount(
      where: { id: $id }
      data: {
        name: $name
        description: $description
        geofenceLimit: $geofenceLimit
        rewardLimit: $rewardLimit
        cta: $cta
        url: $url
        imageUrl: $imageUrl
      }
    ) {
      id
      name
      description
      geofenceLimit
      rewardLimit
      cta
      url
      imageUrl
    }
  }
`

const users = {
  CREATE,
  UPDATE,
  GENERAL,
  CREDENTIALS,
  ACCOUNT,
}

export default users
