import React from 'react'

import Section from '../Section'
import General from './General'
import Credentials from './Credentials'
import Account from './Account'
import CTA from './CTA'
import Limits from './Limits'

const Settings = () => (
  <>
    <Section
      heading="Profile"
      text="Your personal account information and preferences"
    >
      <General />
      <Credentials />
    </Section>
    <Section
      heading="Account"
      text="Information about your account"
    >
      <Account />
      <CTA />
      <Limits />
    </Section>
  </>
)

export default Settings
