import { gql } from 'apollo-boost'

const LIST_ALL = gql`
query Geofences {
  geofences {
      id
      name
      status
      account {
        id
        name
      }  
    }
  }
`

const LIST = gql`
query Geofences($account: ID!) {
  geofences(where: { account: { id: $account } }) {
      id
      name
      status
      account {
        id
        name
      }  
    }
  }
`

const EDIT = gql`
  query Geofence($id: ID!) {
    geofence(where: { id: $id }) {
      id
      radius
      name
      latitude
      longitude
      status
      points
      refreshRate   
    }
  }
`

const geofences = {
  LIST_ALL,
  LIST,
  EDIT,
}

export default geofences
