import { gql } from 'apollo-boost'

const LIST_ALL = gql`
query Rewards {
  rewards {
    id
    name
    status
    account {
      id
      name
    }
  }
}
`

const LIST = gql`
query Rewards($account: ID!) {
  rewards(where: { account: { id: $account } }) {
    id
    name
    status
    account {
      id
      name
    }
  }
}
`

const EDIT = gql`
  query Reward($id: ID!) {
    reward(where: { id: $id }) {
      id
      name
      description
      points
      status
    }
  }
`

const rewards = {
  LIST_ALL,
  LIST,
  EDIT,
}

export default rewards
