import {
  object,
  string,
  number,
  boolean,
} from 'yup'

const email = string().email('Please provide a valid email address').required('Email address is a required field')
const password = string().required('Password is a required field').min(6, 'Password must be at least 6 characters in length')
const passwordNotRequired = string().min(6, 'Password must be at least 6 characters in length')
const firstName = string().required('First name is required')
const lastName = string().required('Last name is required')
const name = string().required('Name is required')
const description = string().required('Description is required')
const geofenceLimit = number().required('Zone limit is required').min(1, 'Limit must be greater than 0').integer('Limit must be an integer')
const rewardLimit = number().required('Reward limit is required').min(1, 'Limit must be greater than 0').integer('Limit must be an integer')
const radius = number().required('Radius is a required field').moreThan(0).integer('Radius must be an integer')
const latitude = number().required('Latitude is a required field')
const longitude = number().required('Longitude is a required field')
const points = number().required('Points is a required field').moreThan(0).integer('Points must be an integer')
const refreshRate = number().required('Refresh rate is a required field').moreThan(0).integer('Refresh rate must be an integer')
const status = boolean()
const admin = boolean()
const account = string().nullable()
const cta = string().required('Call to action is a required field')
const url = string().url().required('URL is a required field')
const imageUrl = string().url().required('Image URL is a required field')

const passwordResetSchema = object().shape({
  email,
})

const signInSchema = object().shape({
  email,
  password,
})

const createGeofenceSchema = object().shape({
  name,
  radius,
  latitude,
  longitude,
  status,
  points,
  refreshRate,
  account,
})

const updateGeofenceSchema = object().shape({
  name,
  radius,
  latitude,
  longitude,
  status,
  points,
  refreshRate,
})

const createRewardSchema = object().shape({
  name,
  description,
  points,
  status,
  account,
})

const updateRewardSchema = object().shape({
  name,
  description,
  points,
  status,
})

const createUserSchema = object().shape({
  email,
  password,
  firstName,
  lastName,
  admin,
})

const updateUserSchema = object().shape({
  email,
  firstName,
  lastName,
  admin,
})

const updateGeneralSchema = object().shape({
  firstName,
  lastName,
})

const updateCredentialsSchema = object().shape({
  email,
  password: passwordNotRequired,
})

const updateAccountSchema = object().shape({
  name,
  description,
  geofenceLimit,
  rewardLimit,
  cta,
  url,
  imageUrl,
})

const updateAccountSchemaNoLimit = object().shape({
  name,
  description,
  imageUrl,
})

const updateAccountCTA = object().shape({
  cta,
  url,
})

export {
  passwordResetSchema,
  signInSchema,
  createGeofenceSchema,
  updateGeofenceSchema,
  createRewardSchema,
  updateRewardSchema,
  createUserSchema,
  updateUserSchema,
  updateGeneralSchema,
  updateCredentialsSchema,
  updateAccountSchema,
  updateAccountSchemaNoLimit,
  updateAccountCTA,
}
