import React from 'react'
import { useQuery } from 'react-apollo-hooks'
import { Text } from 'rebass'
import get from 'lodash.get'

import Authenticated from './Authenticated'
import Loading from '../Loading'

import queries from '../../queries'

const GetRole = () => {
  const { data, loading, error } = useQuery(queries.users.ME, { suspend: false })

  if (loading) return <Loading full />
  if (error) return <Text color="error">{error.message}</Text>

  const admin = get(data, 'me.roles', []).includes('ADMIN')
  const me = get(data, 'me', {})

  return (
    <Authenticated admin={admin} me={me} />
  )
}

export default GetRole
