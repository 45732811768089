import React, { useState } from 'react'
import { useMutation } from 'react-apollo-hooks'
import { navigate } from '@reach/router'

import {
  TextInput,
  InputGroup,
  Toggle,
  FormSection,
  Form,
} from '../Form'
import Card from '../Card'
import Section from '../Section'

import { createUserSchema } from '../../utilities/validations'
import mutations from '../../mutations'
import routes from '../../constants/routes'

const initialValues = {
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  admin: false,
}

const Create = () => {
  const [error, setError] = useState(null)
  const createUser = useMutation(mutations.users.CREATE)

  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true)

    const variables = {
      ...createUserSchema.cast(values),
      roles: values.admin ? ['ADMIN'] : ['CLIENT'],
    }
    console.log(variables)
    try {
      await createUser({ variables })
      navigate(routes.USERS_ROOT)
    } catch (e) {
      setError(e.message)
    }

    setSubmitting(false)
  }

  return (
    <Section
      heading="Create a New User"
      text="A user will be able to login to this application. Admin rights should only be granted to Dot Dot Data team members."
      bigHeader
    >
      <Card>
        <Form
          initialValues={initialValues}
          validationSchema={createUserSchema}
          onSubmit={onSubmit}
          submitLabel="Create User"
          error={error}
        >
          <FormSection>
            <TextInput name="email" placeholder="Enter an email address" label="Email Address" />
            <TextInput name="password" placeholder="Enter a password" label="Password" />
            <Toggle name="admin" label="Admin" />
          </FormSection>

          <FormSection
            heading="General Information"
          >
            <InputGroup>
              <TextInput name="firstName" placeholder="Enter a first name" label="First Name" />
              <TextInput name="lastName" placeholder="Enter a last name" label="Last Name" />
            </InputGroup>
          </FormSection>
        </Form>
      </Card>
    </Section>
  )
}

export default Create
