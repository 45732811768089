import React from 'react'
import { Router, Redirect } from '@reach/router'

import Home from '../Home'
// import GettingStarted from '../Home/GettingStarted' // TODO: uncomment when presentable

import Account from '../Account'
import Settings from '../Account/Settings'

import Geofences from '../Geofences'
import GeofenceList from '../Geofences/List'
import GeofencesCreate from '../Geofences/Create'
import GeofencesEdit from '../Geofences/Edit'

import Rewards from '../Rewards'
import RewardsList from '../Rewards/List'
import RewardsCreate from '../Rewards/Create'
import RewardsEdit from '../Rewards/Edit'

import Users from '../Users'
import UsersList from '../Users/List'
import UsersCreate from '../Users/Create'
import UsersEdit from '../Users/Edit'

import NotFound from '../NotFound'

import routes from '../../constants/routes'

const AuthenticatedRoutes = ({ admin, me }) => (
  <Router primary={false /* stop accessibility scroll to new content */}>
    <Home path="/" admin={admin}>

      <NotFound default />
      {/* <GettingStarted path="/" /> // TODO: make this component presentable then delete below Redirect */}
      <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />

      <Account path={routes.ACCOUNT}>
        <NotFound default />
        <GeofenceList path={routes.GEOFENCES} admin={admin} me={me} />
        <RewardsList path={routes.REWARDS} admin={admin} me={me} />
        {admin && <UsersList path={routes.USERS} />}
        <Settings path={routes.SETTINGS} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />
      </Account>

      <Geofences path={routes.GEOFENCES}>
        <GeofencesCreate path={routes.NEW} admin={admin} me={me} />
        <GeofencesEdit path={routes.ID} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.GEOFENCES}`} noThrow />
      </Geofences>

      <Rewards path={routes.REWARDS}>
        <RewardsCreate path={routes.NEW} admin={admin} me={me} />
        <RewardsEdit path={routes.ID} />
        <Redirect from="/" to={`${routes.ACCOUNT}/${routes.REWARDS}`} noThrow />
      </Rewards>

      {admin && (
        <Users path={routes.USERS}>
          <UsersCreate path={routes.NEW} />
          <UsersEdit path={routes.ID} />
          <Redirect from="/" to={`${routes.ACCOUNT}/${routes.USERS}`} noThrow />
        </Users>
      )}

    </Home>
  </Router>
)

export default AuthenticatedRoutes
