import { defaultBreakpoints } from 'styled-system'

const breakpoints = [...defaultBreakpoints]

const colors = {
  primary: '#E96364',
  secondary: '#6BB8E4',
  accent: '#12CE66',
  header: '#F9FAFC',
  background: '#F9FAFC',
  warning: '#EFC210',
  error: '#BB1C1C',
  success: '#1AB25E',
  textLight: '#8592A6',
  textNormal: '#3C4858',
}

const theme = {
  breakpoints,
  colors,
}

export {
  theme as default,
  colors,
  breakpoints,
}
